import Prism from 'prismjs';

function init() {
  if (document.querySelector('.js-docs')) {
    Prism.highlightAll();

    const onPageNav = document.querySelector('#toc');
    if (onPageNav) {
      const sections = document.querySelectorAll('[id^="_"]');

      const scrollHandler = (entries) => {
        entries.forEach((entry) => {
          const sectionId = entry.target.id;
          const sectionLink = onPageNav.querySelector(`a[href="#${sectionId}"]`);

          if (!sectionLink) {
            return false;
          }

          if (entry.intersectionRatio > 0) {
            onPageNav.querySelectorAll('.visible').forEach((node) => {
              node.classList.remove('visible');
            });
            sectionLink.parentNode.classList.add('visible');
          }
        });
      };

      const options = {
        rootMargin: '0px 0px -70%',
      };
      const observer = new IntersectionObserver(scrollHandler, options);
      sections.forEach((section) => observer.observe(section));
    }

    // wrap overflow div around tables
    const tables = document.querySelectorAll('table.tableblock');
    tables.forEach((table) => {
      const tableWrapper = document.createElement('div');
      tableWrapper.classList.add('article__tableWrapper');
      table.parentNode.insertBefore(tableWrapper, table);
      tableWrapper.appendChild(table);
    });
  }
}

export { init };
